import React from 'react'

const VulnView = () => {
    return (
        <div>
            <h1>CVE Info</h1>
        </div>
    )
}

export default VulnView
