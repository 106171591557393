import React from 'react'

const ScanReportRiskLevelView = () => {
    const riskLevel = 'LOW'
    
    return (
        <div className={`risk-level ${riskLevel.toLowerCase()}`}>
            RISK LEVEL: {riskLevel}
        </div>
    )
}

export default ScanReportRiskLevelView
