import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import RegistrationForm from '../components/RegistrationForm';
import { useSelector, useDispatch } from 'react-redux';
import Feedback from '../components/shared/Feedback';
import { setFeedback, setError } from '../redux/reducers/registrationReducer';

const Registration = () => {
    const dispatch = useDispatch();
    const isFeedback = useSelector(state => state.registration.feedback);

    useEffect(() => {
        dispatch(setFeedback({ feedback: false }));
        dispatch(setError({ error: false}));
    }, [])

    return (
        <div className='registration w-100
        d-flex justify-content-center align-items-center'>
            <div className="row
                w-75 p-5 
                d-flex justify-content-center">
                <div className="col-md-6 shadow">
                    <div className="container p-5">
                        {!isFeedback ? <RegistrationForm />
                            : <Feedback message='Something went wrong!' />}

                        <div className="mt-4 w-100">
                            <p className="text-center pb-2 w-100">
                                Already have an account?
                                <Link
                                    to='/login'
                                    className="
                                        w-100 ms-2">
                                    <span className="text-light">
                                        Login
                                    </span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registration;
