import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setVulnSeverityCounts } from '../../redux/reducers/scanDataReducer';
import DNSInfo from './DNSInfo';
import SeverityChart from './SeverityChart';

const ScanReportSeverity = () => {
    const dispatch = useDispatch();
    const vulnSeverityCounts = useSelector(state => state.scanData.vulnSeverityCounts);
    const dnsInfo = useSelector(state => state.scanData.dnsInfo);

    return (
        <>
            <DNSInfo dnsData={dnsInfo} />
            <SeverityChart severityCounts={vulnSeverityCounts} />
        </>
    )
}

export default ScanReportSeverity
