import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserProfile from '../UserProfile';



const user = {
    profileImage: 'https://www.shareicon.net/data/128x128/2016/05/24/770117_people_512x512.png',  // Replace with the actual path to the user's profile image
    name: 'John Doe',
    email: 'john.doe@example.com',
    username: 'johndoe',
    organization: 'Example Corp',
    role: 'Security Analyst',
    recentScans: 26,                  
    vulnerabilitiesFound: 5,
    scansScheduled: 3,                 
    riskScore: 'Medium',              
    twoFactorOn: true, 
    changePasswordDate: '2024-01-01',  
  };
  
const Profile = () => {
    const dispatch = useDispatch();

    return (
<div className='courses'>
    <div className="container-fluid g-0 p-4">
        <div className="row">
            <div className="col-md-12 p-4">
                <UserProfile user={user} />
            </div>


        </div>
    </div>
</div>

    )
}

export default Profile
