import React from 'react';
import SecBot from '../assets/images/logo.png';

function Logo() {
    return (
        <div className='logo d-flex flex-column border-bottom'>
            <div className='d-flex justify-content-center mt-4'>
                <img
                    src={SecBot}
                    alt="Logo Image"
                    className="w-50 img-fluid" />

            </div>
            
        </div>
    )
}

export default Logo
