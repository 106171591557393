import { useEffect } from 'react';
import LoginForm from '../components/LoginForm';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ErrorPage from './Error';
import { setError, setFeedback } from '../redux/reducers/loginReducer';

const Login = () => {
    const dispatch = useDispatch();
    const isError = useSelector(state => state.login.error);

    useEffect(() => {
        dispatch(setFeedback({ feedback: false }));
        dispatch(setError({ error: false}));
    }, [])

    return (
        <div className='login w-100 vh-100
            d-flex justify-content-center align-items-center'>
            <div className="row
                w-75 p-5 
                d-flex justify-content-center">
                <div className="col-md-6 shadow">
                    {!isError ? (
                        <div className="container p-5">
                            <LoginForm />

                            <div className="mt-4 w-100">
                                <p className="text-center pb-2 w-100">
                                    Have you not signed up yet?
                                    <Link
                                    to='/registration'
                                    className="
                                        w-100 ms-2">
                                    <span className="text-light">
                                        Sign Up
                                    </span>
                                </Link>
                                </p>

                                <p className="text-center pb-2 w-100">
                                    <Link
                                    to='/password-reset-request'
                                    className="
                                        w-100 ms-2">
                                    <span className="text-light">
                                        Forgot password
                                    </span>
                                </Link>
                                </p>
                            </div>
                        </div>
                    ) : <ErrorPage />}
                </div>
            </div>
        </div>
    );
}

export default Login;
