import { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from '../api/axios';
import { setAuth, setIsVerified, setUID } from '../redux/reducers/authReducer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import loginValidationSchema from '../helpers/loginValidationSchema';
import { setLoading, setFeedback, setError, setMessage } from '../redux/reducers/loginReducer';
import { ClipLoader } from 'react-spinners';
import { login } from '../api/auth';

const LoginForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const isLoading = useSelector(state => state.login.loading);
    const isFeedback = useSelector(state => state.login.feedback);
    const message = useSelector(state => state.login.message);

    const initialValues = {
        email: '',
        password: ''
    };

    const handleLogin = async (values, { setSubmitting, resetForm }) => {
        abortControllerRef.current = new AbortController();
        dispatch(setLoading({ loading: true }));

        try {
            const response = await axiosInstance.post(login, {
                email: values.email,
                password: values.password
            }, {
                headers: {
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                if (response.data.is_verified === true) {      
                    dispatch(setAuth({
                        loggedIn: true,
                        email: response.data.email,
                    }));
                    dispatch(setIsVerified({ 
                        isVerified: response.data.is_verified 
                    }));
                    dispatch(setUID({ uid: response.data.id }));
                    dispatch(setLoading({ loading: false }));
                    resetForm();
                    navigate('/', {
                        state: { from: location },
                        replace: true,
                    });
                } else if (response.data.is_verified === false) {
                    dispatch(setAuth({
                        loggedIn: false,
                        email: response.data.email,
                    }));
                    dispatch(setIsVerified({ 
                        isVerified: response.data.is_verified 
                    }));
                    dispatch(setUID({ uid: response.data.id }));
                    dispatch(setLoading({ loading: false }));
                    resetForm();
                    navigate('/verification', {
                        state: { from: location },
                        replace: true,
                    });
                }
            } else {
                dispatch(setLoading({ loading: false }));
                dispatch(setFeedback({ feedback: false }));
                resetForm();
                navigate('/error', {
                    state: { from: location },
                    replace: true,
                });
            }
        } catch (err) {
            if (err.response.status === 401) {
                dispatch(setFeedback({ feedback: true }));
                dispatch(setMessage({ message: err.response.data.detail }));
                dispatch(setLoading({ loading: false }));
            } else {
                dispatch(setLoading({ loading: false }));
                dispatch(setFeedback({ feedback: false }));
                resetForm();
                navigate('/error', {
                    state: { from: location },
                    replace: true,
                });
            };
        } finally {
            dispatch(setLoading({ loading: false }));
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={handleLogin}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div>
                        <h3 className="text-center mt-2">
                            Login
                        </h3>
                    </div>

                    <div className="mt-3">
                        <h6 className='
                            text-danger text-center'>
                            {isFeedback ?  message : ''}
                        </h6>
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="email"
                            className="form-label">
                            Email
                        </label>
                        <Field
                            type="text"
                            id="email"
                            name="email"
                            className="form-control" />
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="password"
                            className="form-label">
                            Password
                        </label>
                        <Field
                            type="password"
                            id="password"
                            name="password"
                            className="form-control" />
                        <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <button
                        type="submit"
                        className="d-flex
                            justify-content-center
                            btn btn-secondary btn-outline-primary 
                            text-light 
                            w-100 mt-4"
                        disabled={isSubmitting || isLoading}>
                        {isLoading ? <ClipLoader size={21} color={"#fff"} />
                            : 'Login'}
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default LoginForm
