import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './Dashboard';
import VulnView from './VulnView';
import ScanReport from './ScanReport';

function Home() {
    const dispatch = useDispatch();
    const subPage = useSelector((state) => state.route.value.subpage);

    return (
        <div className='home'>
            <div className="container-fluid g-0">
                <div className="row">
                    <div className="col-md-12 p-4">
                        { subPage === 'scantaskview' ? <ScanReport />
                            : subPage === 'vulnview' ? <VulnView />
                            : <Dashboard /> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
