import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import DateTime from '../shared/DateTime';
import {setLoading, setError} from '../../redux/reducers/scanReportReducer'; 
import { ClipLoader } from 'react-spinners';   

const DNSInfo = ({ dnsData }) => {
    let data = {
        name_server: "N/A",
        registry_domain_id: "N/A",
        creation_date: "N/A",
        registrar: "N/A",
        registrar_url: "N/A"
    }

    if (dnsData === undefined){
        let data = {
            name_server: "N/A",
            registry_domain_id: "N/A",
            creation_date: "N/A",
            registrar: "N/A",
            registrar_url: "N/A"
        }
    } else {
        dnsData.forEach(item => {
            if (item.tool_name === "whois"){
                let data = {
                    name_server: item.tool_output.name_server,
                    registry_domain_id: item.tool_output.registry_domain_id,
                    
                    registrar: item.tool_output.registrar,
                    registrar_url: item.tool_output.registrar_url
                }
            }
        });
    }

    

    return (
        <div className="dns-info">
            <div className="title my-3">DNS Information</div>
            <div>
                <div><strong>Nameserver:</strong> {data.name_server}</div>
                <div><strong>Registry Domain ID:</strong> {data.registry_domain_id}</div>
                
                
                <div><strong>Registrar:</strong> {data.registrar}</div>
                <div><strong>Registrar URL:</strong> <a href={data.registrar_url}>{data.registrar_url}</a></div>
            </div>
            

        </div>
    );
};

export default DNSInfo;