import React from 'react';
import { Table } from 'reactstrap';

const ScanReportListView = ({ data, title, type, headers }) => {

    if (data === undefined) {
        return null;
    }

    if(data.length === 0) {
        return null;
    }

    if (type === '1') {
        return (
            <div className='table-container mt-5'>
                <h5 className="my-2">
                    {title}
                </h5>
                <Table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row.id}</td>
                                <td>{row.is_exploit}</td>
                                <td>{row.cvss}</td>
                                <td>{row.type}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div className='table-container mt-3'>
            <h5 className="my-2">
                {title}
            </h5>
            
            <Table className='table table-hover table-striped'>
                <tbody>
                    {data?.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{row}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ScanReportListView
