import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { axiosInstance } from '../api/axios';
import { setVulnData, setVulnSeverityCounts, setDBServerityCounts, setTotalVulnCount } from '../redux/reducers/scanDataReducer';
import { GrPrevious, GrFormNext } from "react-icons/gr";

const VulnDataList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const vulnData = useSelector(state => state.scanData.filteredVulnData);
    const page = useSelector(state => state.scanData.page);
    const totalPages = useSelector(state => state.scanData.totalPages);

    const columns = [
        {
            Header: 'Name',
            assessor: (row) => row.data.tool_data.tool_output.title,
            minWidth: 70

        },
        {
            Header: 'Severity',
            assessor: (row) => row.severity,
            minWidth: 30
        },
        {
            Header: 'Protocol',
            assessor: (row) => row.data.tool_data.tool_output.protocol,
            minWidth: 30
        },
        {
            Header: 'Endpoint',
            assessor: (row) => row.data.tool_data.tool_output.endpoint
        },
        {
            Header: 'Info',
            assessor: (row) => row.data.tool_data.tool_output.related_info,
            minWidth: 70
        },
    ]

    return (
        <div className='table-container'>
            <Table className='table table-hover table-striped'>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index} style={{ maxWidth: column.minWidth || 70 }}>
                                {column.Header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {vulnData?.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex} style={{ maxWidth: column.minWidth || 30 }} className='cell'>
                                    {typeof column.assessor === 'function' ? column.assessor(row) : row[column.assessor]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div>
                {page > 1 && (
                    <GrPrevious
                        className='btn btn-primary p-3 mx-1'
                        
                        disabled={page === 1}
                    />
                )}

                {page > 1 && (
                    <GrFormNext
                        className='btn btn-primary p-3'
                        
                        disabled={page === totalPages}
                    />
                )}
            </div>
        </div>
    )
}

export default VulnDataList