import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from '../api/axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import registrationValidationSchema from '../helpers/registrationValidationSchema';
import { registration } from '../api/auth';
import { setLoading, setFeedback, setError, setMessage } from '../redux/reducers/registrationReducer';
import { ClipLoader } from 'react-spinners';

const RegistrationForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const isLoading = useSelector(state => state.registration.loading);

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    const handleRegistration = async (values, { setSubmitting, resetForm }) => {
        abortControllerRef.current = new AbortController();
        dispatch(setLoading({ loading: true }));
        
        try {
            let user_data = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password: values.password,
                password_confirm: values.confirmPassword
            }
            const response = await axiosInstance.post(registration, user_data, {
                headers: {
                    'Content-type': 'application/json',
                }
            });
            
            if (response.status === 201) {
                dispatch(setLoading({ loading: false }));
                resetForm();
                navigate('/login', {
                    state: { from: location },
                    replace: true
                });
            } else {
                dispatch(setLoading({ loading: false }));
                dispatch(setFeedback({ feedback: true }));
            }
        } catch (err) {
            dispatch(setLoading({ loading: false }));
            dispatch(setFeedback({ feedback: true }));
        } finally {
            dispatch(setLoading({ loading: false }));
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={registrationValidationSchema}
            onSubmit={handleRegistration}
            >
            {({ isSubmitting }) => (
                <Form>
                    <div>
                        <h3 className="text-center mt-2">
                            Create an Account
                        </h3>
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="firstName"
                            className="form-label">
                            First Name:
                        </label>
                        <Field
                            type="text"
                            id="firstName"
                            name="firstName"
                            className="form-control" />
                        <ErrorMessage
                            name="firstName"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="lastName"
                            className="form-label">
                            Last Name:
                        </label>
                        <Field
                            type="text"
                            id="lastName"
                            name="lastName"
                            className="form-control" />
                        <ErrorMessage
                            name="lastName"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="email"
                            className="form-label">
                            Email:
                        </label>
                        <Field
                            type="text"
                            id="email"
                            name="email"
                            className="form-control" />
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="password"
                            className="form-label">
                            Password:
                        </label>
                        <Field
                            type="password"
                            id="password"
                            name="password"
                            className="form-control" />
                        <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="confirmPassword"
                            className="form-label">
                            Confirm Password:
                        </label>
                        <Field
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control" />
                        <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <button
                        type="submit"
                        className="d-flex
                            justify-content-center
                            btn btn-secondary btn-outline-primary 
                            text-light 
                            w-100 mt-4"
                        disabled={isSubmitting || isLoading}>
                        { isLoading ? <ClipLoader size={21} color={"#fff"}/> 
                            : 'Sign Up' }
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default RegistrationForm
