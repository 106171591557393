import { useEffect, useState } from 'react';
import Image from '../../assets/images/image.png';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container, Row, Col, Card, CardHeader,
    CardBody, CardTitle, ListGroup, ListGroupItem,
    Badge, Button, Table
} from 'reactstrap';
import { setScanProgress } from '../../redux/reducers/scanDataReducer';
import VulnDataList from '../VulnDataList';
import { APP_WEBSOCKET_URI } from '../../api/config';
import SeverityChart from './SeverityChart';
import SeverityCount from './SeverityCount';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ScanReportHeader from './ScanReportHeader';
import ScanReportCVEList from './ScanReportCVEList';
import ScanReportListView from './ScanReportListView';
import ScanReportRiskLevelView from './ScanReportRiskLevelView';
import ScanReportInfo from './ScanReportInfo';
import ScanReportSeverity from './ScanReportSeverity';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const ScanReport = () => {
    const dispatch = useDispatch();
    const uid = useSelector(state => state.auth.uid);
    const dnsInfo = useSelector(state => state.scanData.dnsInfo);
    let vulnSeverityCounts = useSelector(state => state.scanData.vulnSeverityCounts);
    if (vulnSeverityCounts === undefined){
        vulnSeverityCounts = {
            total: 0,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 0,
        }
    }
    console.log(uid)

    /*useEffect(() => {
        const websocket = new WebSocket(
            `${APP_WEBSOCKET_URI}?id=${uid}`
        );

        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.data) {
                //dispatch(setScanProgress(message.data));
            }
        };

        return () => {
            websocket.close();
        };
    }, []);*/

    return (
        <div className='container summary-report'>
            <div className="row header">
                <div className="col-md-3 summary-title">
                    <h4 className="p-3">
                        <strong>SCAN REPORT</strong>
                    </h4>
                </div>

                <div className="col-md-6 scan-info ps-5">
                    <ScanReportHeader />
                </div>

                <div className="col-md-3">
                    <ScanReportRiskLevelView />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 info">
                    <ScanReportSeverity />
                </div>
            </div>

            <div className="row px-0">
                <div className="severity-counts px-0">
                    <SeverityCount severityCount={vulnSeverityCounts.critical} severityLevel='Critical' />
                    <SeverityCount severityCount={vulnSeverityCounts.high} severityLevel='High' />
                    <SeverityCount severityCount={vulnSeverityCounts.medium} severityLevel='Medium' />
                    <SeverityCount severityCount={vulnSeverityCounts.low} severityLevel='Low' />
                    <SeverityCount severityCount={vulnSeverityCounts.info} severityLevel='Informational' />
                </div>
            </div>

            <ScanReportInfo />
        </div>
    )
}

export default ScanReport;
