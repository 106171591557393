import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPortInfo } from '../../redux/reducers/scanDataReducer';
import { Table } from 'reactstrap';

const ScanReportPortList = ({data}) => {
    

    if (data.length === 0) {
        return null;
    }

    return (
        <div className='table-container mt-5'>
            <h5 className="my-2">
                Open Ports
            </h5>
            <Table className='table table-hover table-striped'>
                <thead>
                    <tr>
                        <th>Port</th>
                        <th>State</th>
                        <th>Service</th>
                        <th>Product</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{row.portid}</td>
                            <td>{row.state}</td>
                            <td>{row.service}</td>
                            <td>{row.product}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ScanReportPortList
