import Logo from '../components/Logo';
import SideNavBar from '../components/SideNavBar';
import UserRole from '../components/UserRole';
import Main from '../components/Main';
import { axiosInstance } from '../api/axios';

const Dashboard = () => {
    return (
        <div className='dashboard'>
            <div className="container-fluid g-0 m-0 p-0">
                <div className="row g-0 p-0">
                    <div className="col-md-3 bg-secondary">
                        <Logo />
                        <UserRole />
                        <SideNavBar />
                    </div>
                    <div className="col-md-9">
                        <Main />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard