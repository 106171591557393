import { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosUnAuth } from '../api/axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { passwordResetRequestValidationSchema } from '../helpers/passwordResetValidationSchema';
import { setLoading, setFeedback, setError, setMessage } from '../redux/reducers/passwordResetReducer';
import { ClipLoader } from 'react-spinners';
import { password_reset_request } from '../api/auth';

const PasswordResetRequestForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const isLoading = useSelector(state => state.password_reset.loading);

    const initialValues = {
        email: '',
    };

    const handlePasswordResetRequest = async (values, { setSubmitting, resetForm }) => {
        abortControllerRef.current = new AbortController();
        dispatch(setLoading({ loading: true }));

        try {
            const response = await axiosUnAuth.post(password_reset_request, {
                email: values.email,
            }, {
                headers: {
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                dispatch(setLoading({ loading: false }));
                resetForm();
                dispatch(setFeedback({ feedback: true }));
            } else {
                navigate('/error', {
                    state: { from: location },
                    replace: true
                });
                dispatch(setError({ error: true }))
            }
        } catch (err) {
            dispatch(setMessage({ message: err.response.data.detail }));
            dispatch(setError({ error: true }))
        } finally {
            dispatch(setLoading({ loading: false }));
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={passwordResetRequestValidationSchema}
            onSubmit={handlePasswordResetRequest}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div>
                        <h3 className="text-center mt-2">
                            Enter Your Email Address
                        </h3>
                    </div>

                    <div className="mt-3">
                        <label
                            htmlFor="email"
                            className="form-label">
                            Email
                        </label>
                        <Field
                            type="text"
                            id="email"
                            name="email"
                            className="form-control" />
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <button
                        type="submit"
                        className="d-flex
                            justify-content-center
                            btn btn-secondary btn-outline-primary 
                            text-light 
                            w-100 mt-4">
                        {isLoading ? <ClipLoader size={21} color={"#fff"} />
                            : 'Request Password Reset'}
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default PasswordResetRequestForm;