import * as Yup from 'yup';

const validateNoShellCommands = (value) => {
    const shellCommandPattern = /[`;&|${}<>]/g;
    if (shellCommandPattern.test(value)) {
        return "Invalid Input.";
    }
};

export const scanValidationSchema = Yup.object().shape({
    domainName: Yup.string()
        .min(3, 'Invalid Inpout')
        .max(128, 'Invalid Input')
        .matches(
          /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/,
          'Invalid domain name'
        )
        .required('Domain Name is required')
        .test('no-shell-commands', 'Invalid characters in the input',
            value => !validateNoShellCommands(value)),
});
