import {useEffect, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { scan } from '../../api/scan';
import { axiosInstance } from '../../api/axios';
import SocketChannel from '../common/SocketChannel';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { scanValidationSchema } from '../../helpers/scanValidationSchema';
import { setLoading, setFeedback, setError } from '../../redux/reducers/scanReducer';
import { setData } from '../../redux/reducers/scanDataReducer';
import { ClipLoader } from 'react-spinners';

const ScanForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const isLoading = useSelector(state => state.scan.loading);
    const isFeedback = useSelector(state => state.scan.feedback);

    const initialValues = {
        domainName: '',
    };

    const handleScanRequest = async (values, { setSubmitting, resetForm }) => {
        abortControllerRef.current = new AbortController();
        dispatch(setLoading({ loading: true }));

        try {
            const response = await axiosInstance.post(scan, {
                domain_name: values.domainName,
            }, {
                headers: {
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                if (response.data.status === true) {
                    dispatch(setLoading({ loading: false }));
                    resetForm();
                    dispatch(setFeedback({ feedback: true }));
                } else if (response.data.status === false) {
                    navigate('/error', {
                        state: { from: location },
                        replace: true
                    });
                    dispatch(setError({ error: true }))
                }
            } else {
                navigate('/error', {
                    state: { from: location },
                    replace: true
                });
                dispatch(setError({ error: true }))
            }
        } catch (err) {
            navigate('/error', {
                state: { from: location },
                replace: true
            });
            dispatch(setError({ error: true }))
        } finally {
            dispatch(setLoading({ loading: false }));
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={scanValidationSchema}
            onSubmit={handleScanRequest}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div className="mt-3">
                        <label
                            htmlFor="domainName"
                            className="form-label text-dark">
                            Domain Name
                        </label>
                        <Field
                            type="text"
                            id="domainName"
                            name="domainName"
                            className="form-control bg-light" />
                        <ErrorMessage
                            name="domainName"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <button
                        type="submit"
                        className="d-flex
                            justify-content-center
                            btn btn-secondary btn-outline-primary 
                            text-light 
                            w-25 mt-4"
                        disabled={isSubmitting || isLoading}>
                        {isLoading ? <ClipLoader size={21} color={"#000"} />
                            : 'Scan'}
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default ScanForm
