import { Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import { PrivateRoute } from './components/common/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Registration from "./pages/Registration";
import Scan from "./components/dashboard/Scan";
import Profile from "./components/dashboard/Profile";
import ErrorPage from './pages/Error';
import EmailVerification from "./pages/EmailVerification";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetRequest from "./pages/PasswordResetRequest";

const App = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/registration' element={<Registration />} />
      <Route path='/error' element={<ErrorPage />} />
      <Route path='/verification' element={
        <PrivateRoute>
          <EmailVerification />
        </PrivateRoute>
      } />
      <Route path='/password-reset-request' element={<PasswordResetRequest />} />
      <Route exact path='/password-reset/:uid/:token' element={<PasswordReset />} />
      <Route path='/' element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      } >
        <Route exact path='scan' element={<Scan />} />
        <Route exact path='profile' element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default App;
