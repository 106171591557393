import React from 'react';

const SeverityCount = ({ severityCount, severityLevel }) => {
    const severityColor = {
        critical: '2px solid #dc3545',
        high: '2px solid #ffc107',
        medium: '2px solid #17a2b8',
        low: '2px solid #28a745',
        informational: '2px solid #007bff',
    }[severityLevel.toLowerCase()];

    return (
        <div className='severity-counts-box'>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div
                    style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        border: severityColor,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        position: 'relative',
                    }}
                >
                    <h3>
                        {severityCount}
                    </h3>
                </div>

                <div style={{textAlign: 'center', marginTop: '1rem'}}>
                    <h6 style={{color: severityColor}}>{severityLevel}</h6>
                </div>
            </div>
        </div>
    );
};

export default SeverityCount;