import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const SeverityChart = ({ severityCounts }) => {
    if (severityCounts === undefined){
        severityCounts = {
            total: 0,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 1,
        }
    }

    const data = {
        labels: ['Critical', 'High', 'Medium', 'Low', 'Informational'],
        datasets: [
            {
                label: 'Severity',
                data: [
                    severityCounts.critical,
                    severityCounts.high,
                    severityCounts.medium,
                    severityCounts.low,
                    severityCounts.info
                ],
                backgroundColor: ['#ff4747', '#ffa500', '#ffff00', '#00ff00', '#00bfff'],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                align: 'left',
                labels: {
                    boxWidth: 20,
                    padding: 20,
                },
            },
        },
        maintainAspectRatio: false,
    };

    

    return (
        <div style={{ position: 'relative', width: '600px', height: '300px' }}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default SeverityChart;