const ErrorPage = () => {
    return (
        <div className='error-page'>
            <div className="row 
                justify-content-center 
                mt-5 mx-3 pb-5 px-5">
                <div className="col-md-6 shadow">
                    <div className="container p-5">
                        <div className="p-5">
                            <h4 className="text-center pb-2">
                                Something went wrong...
                            </h4>

                            <h6 className="text-center pb-2">
                                Better Luck Next Time
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;