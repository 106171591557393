import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routing } from '../redux/reducers/routeReducer';
import { setNavItemActive, resetState } from '../redux/reducers/navItemReducer';

const SideNavBar = () => {
    const dispatch = useDispatch();
    const isNavItemActive = useSelector((state) => state.navitem.value);

    return (
        <div className="
            sidebar-nav-list 
            d-flex
            w-100
            flex-column 
            g-0 m-0 mt-4 
            justify-content-start 
            align-items-center">
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link
                        to="/"
                        className={"nav-link sidebar-nav-list-item d-flex w-100 p-3 text-bg-dark "+(isNavItemActive.dashboard?"sidebar-nav-list-item-active":"bg-primary")}
                        onClick={() => {
                            dispatch(resetState());
                            dispatch(setNavItemActive({ dashboard: true }));
                            dispatch(routing({ mainpage: "dashboard"}));
                        }}>
                        Dashboard
                    </Link>
                </li>

                <li className="nav-item">
                    <Link
                        to="/Scan"
                        onClick={() => {
                            dispatch(resetState());
                            dispatch(setNavItemActive({ scan: true }));
                            dispatch(routing({ mainpage: "scan"}));
                        }}
                        className={"nav-link sidebar-nav-list-item d-flex w-100 p-3 text-bg-dark "+(isNavItemActive.scan?"sidebar-nav-list-item-active":"bg-primary")}>
                        Pentesting
                    </Link>
                </li>

                <li className="nav-item">
                    <Link
                        to="/profile"
                        onClick={() => {
                            dispatch(resetState());
                            dispatch(setNavItemActive({ profile: true }));
                            dispatch(routing({ mainpage: "profile"}));
                        }}
                        className={"nav-link sidebar-nav-list-item d-flex w-100 p-3 text-bg-dark "+(isNavItemActive.profile?"sidebar-nav-list-item-active":"bg-primary")}>
                        Profile
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default SideNavBar;