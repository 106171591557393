import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PasswordResetForm from '../components/PasswordResetForm';
import { axiosInstance } from '../api/axios';
import { password_reset } from '../api/auth';
import Feedback from '../components/shared/Feedback';

const PasswordReset = () => {
    const isFeedback = useSelector(state => state.password_reset.feedback);
    const { uid, token } = useParams();

    return (
        <div className='password-reset w-100 vh-100
            d-flex justify-content-center align-items-center'>
            <div className="row
                w-75 p-5 
                d-flex justify-content-center">
                <div className="col-md-6 shadow">
                    {!isFeedback ? (
                        <div className="container p-5">
                            <PasswordResetForm uid={uid} token={token} />
                        </div>
                    ) : (
                        <div className="container p-5">
                            <Feedback message="Password was changed successfully!" />

                            <div className="mt-4 w-100">
                                <h6 className="text-center pb-2 w-100">
                                    Login to the Account
                                </h6>

                                <Link
                                    to='/login'
                                    className="btn btn-secondary 
                                        btn-outline-primary 
                                        text-light 
                                        w-100">
                                    <span className="text-light">
                                        Login
                                    </span>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;