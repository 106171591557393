import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OTPVerificationForm from '../components/OTPVerificationForm';
import OTPSend from '../components/OTPSend';
import Feedback from '../components/shared/Feedback';

const EmailVerification = () => {
    const isVerified = useSelector(state => state.auth.isVerified);

    return (
        <div className='email-verification w-100 vh-100
            d-flex justify-content-center align-items-center'>
            <div className="row
                w-75 p-5 
                d-flex justify-content-center">
                <div className="col-md-6 shadow">
                    { !isVerified ? (
                        <div className="container p-5">
                            <div>
                                <h3 className="text-center mt-2">
                                    Email Verification
                                </h3>
                            </div>
                            <OTPVerificationForm />
                            <OTPSend />
                        </div>
                    ) : (
                        <div className="container p-5">
                            <Feedback message="Email was verified!" />

                            <div className="mt-4 w-100">
                                <h6 className="text-center pb-2 w-100">
                                    Login to the Account
                                </h6>

                                <Link
                                    to='/login'
                                    className="btn btn-secondary 
                                        btn-outline-primary 
                                        text-light 
                                        w-100">
                                    <span className="text-light">
                                        Login
                                    </span>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;