import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { axiosPrivate } from "../api/axios";
import { setAuth } from '../redux/reducers/authReducer';
import { APP_REST_URL } from '../api/config';

const useAxiosPrivate = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Referer']) {
                    config.headers['Referer'] = APP_REST_URL;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;

                if ((error?.response?.status === 401 || error?.response?.status === 403) && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    dispatch(setAuth({ 
                        loggedIn: false,
                     }));
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [])

    return axiosPrivate;
}

export default useAxiosPrivate;