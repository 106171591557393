import * as Yup from 'yup';

const validateNoShellCommands = (value) => {
    const shellCommandPattern = /[`;&|${}<>]/g;
    if (shellCommandPattern.test(value)) {
        return "Invalid Input.";
    }
};

const registrationValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'First name must be at least 2 characters')
        .max(50, 'First name cannot exceed 50 characters')
        .required('First name is required')
        .test('no-shell-commands', 'Invalid characters in first name.',
            value => !validateNoShellCommands(value)),
    lastName: Yup.string()
        .min(2, 'Last name must be at least 2 characters')
        .max(50, 'Last name cannot exceed 50 characters')
        .required('Last name is required')
        .test('no-shell-commands', 'Invalid characters in last name.',
            value => !validateNoShellCommands(value)),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required')
        .test('no-shell-commands', 'Invalid characters in email.',
            value => !validateNoShellCommands(value)),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(30, 'Password cannot exceed 30 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

export default registrationValidationSchema;
