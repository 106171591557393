import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PasswordResetRequestForm from '../components/PasswordResetRequestForm';
import Feedback from '../components/shared/Feedback';
import OTPVerificationForm from '../components/OTPVerificationForm';
import OTPSend from '../components/OTPSend';
import { 
    setFeedback, 
    setOTPSent,
    setError,
    setMessage } 
from '../redux/reducers/passwordResetReducer';

const PasswordResetRequest = () => {
    const isFeedback = useSelector(state => state.password_reset.feedback);

    return (
        <div className='password-reset-request w-100 vh-100
            d-flex justify-content-center align-items-center'>
            <div className="row
                w-75 p-5 
                d-flex justify-content-center">
                <div className="col-md-6 shadow">
                    { !isFeedback ? (
                        <div className="container p-5">
                            <PasswordResetRequestForm />
                        </div>
                    ) : (
                        <div className="container p-5">
                            <Feedback message="Password reset email sent successfully!" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PasswordResetRequest;