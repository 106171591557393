import {configureStore} from '@reduxjs/toolkit';
import routeReducer from './reducers/routeReducer';
import navItemReducer from './reducers/navItemReducer';
import authReducer from './reducers/authReducer';
import registrationReducer from './reducers/registrationReducer';
import loginReducer from './reducers/loginReducer';
import emailVerificationReducer from './reducers/emailVerificationReducer';
import profileReducer from './reducers/profileReducer';
import passwordResetReducer from './reducers/passwordResetReducer';
import scanDataReducer from './reducers/scanDataReducer';
import scanReducer from './reducers/scanReducer';
import scanReportReducer from './reducers/scanReportReducer';

const rootReducer = {
    auth: authReducer,
    route: routeReducer,
    navitem: navItemReducer,
    registration: registrationReducer,
    login: loginReducer,
    email_verification: emailVerificationReducer,
    profile: profileReducer,
    password_reset: passwordResetReducer,
    scanData: scanDataReducer,
    scan: scanReducer,
    scanReport: scanReportReducer,
}

const store = configureStore({
    reducer: rootReducer
});

export default store;