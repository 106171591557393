import { useEffect,  useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { axiosInstance } from '../api/axios';
import { scan } from '../api/scan';
import { setFeedback, setScanId } from '../redux/reducers/scanReducer';
import DateTime from './shared/DateTime';
import { routing } from '../redux/reducers/routeReducer';

const ScanTaskList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [scanData, setScanData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const feedback = useSelector((state) => state.scan.feedback);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = scanData.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(scanData.length / recordsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(scan);
        if (response.status === 200) {
          setScanData(response.data.data);
        } else {
          navigate('/error', {
            state: { from: location },
            replace: true,
          });
        }
      } catch (error) {
        navigate('/error', {
          state: { from: location },
          replace: true,
        });
      } finally {
        dispatch(setFeedback({ feedback: false }));
      } 
    }
    fetchData();
  }, [feedback])

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Domain Name</th>
            <th>Created At</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((item) => (
            <tr key={item.id} onClick={() => {
              dispatch(routing({
                mainpage: 'Scan',
                subpage: 'scantaskview'
              }));
              console.log(item.id)
              dispatch(setScanId({ scan_id: item.id}));
            }}>
              <td className="scan-task-item">{item.id}</td>
              <td className="scan-task-item">{item.domain_name}</td>
              <td className="scan-task-item"><DateTime timestamp={item.created_at} /></td>
              <td className="scan-task-item">{item.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div>
        {[...Array(totalPages)].map((_, index) => (
          <button key={index} onClick={() => paginate(index + 1)} className="btn p-1">
            {index + 1}
          </button>
        ))}
      </div>
  </>
  );
};

export default ScanTaskList;