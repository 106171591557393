import { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from '../api/axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { otpValidationSchema } from '../helpers/otpValidationSchema';
import {
    setLoading,
    setFeedback,
    setError,
    setMessage,
    setOTPSent,
    setVerifyLoading
} from '../redux/reducers/emailVerificationReducer';
import { setIsVerified } from '../redux/reducers/authReducer';
import { ClipLoader } from 'react-spinners';
import { email_verification, otp_send } from '../api/auth';

const OTPVerificationForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const isLoading = useSelector(state => state.email_verification.loading);
    const isFeedback = useSelector(state => state.email_verification.feedback);
    const message = useSelector(state => state.email_verification.message);
    const isOTPSent = useSelector(state => state.email_verification.otpSent);
    const verify = useSelector(state => state.email_verification.verify);
    const verifyLoading = useSelector(state => state.email_verification.verifyLoading);
    const [counter, setCounter] = useState(0);

    const initialValues = {
        code: ''
    };

    const handleOTPVerification = async (values, { setSubmitting }) => {
        abortControllerRef.current = new AbortController();
        dispatch(setVerifyLoading({ loading: true }));
        dispatch(setFeedback({ feedback: false }));

        try {
            const response = await axiosInstance.post(email_verification, {
                passcode: values.code
            }, {
                headers: {
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                dispatch(setVerifyLoading({ loading: false }));
                dispatch(setFeedback({ feedback: true }));
                dispatch(setMessage({ message: response.data.message }));
                dispatch(setIsVerified({ isVerified: true }));
            } else {
                dispatch(setLoading({ loading: false }));
                navigate('/error', { 
                    state: { from: location },
                    replace: true
                });
            }
        } catch (err) {
            dispatch(setLoading({ loading: false }));
            dispatch(setFeedback({ feedback: true }));
            dispatch(setMessage({ message: err.response.data.error }));
        } finally {
            dispatch(setVerifyLoading({ loading: false }));
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={otpValidationSchema}
            onSubmit={handleOTPVerification}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div className="mt-3">
                        <div className="mt-3">
                            <h6 className='
                                text-center text-info'>
                                {isFeedback ? message : ''}
                            </h6>
                        </div>

                        <label
                            htmlFor="code"
                            className="form-label">
                            Code
                        </label>

                        <Field
                            type="text"
                            id="code"
                            name="code"
                            className="form-control h-25"
                            disabled={isOTPSent} />

                        <ErrorMessage
                            name="code"
                            component="div"
                            className="text-danger mt-2" />
                    </div>

                    <button
                        type="submit"
                        className="d-flex
                            justify-content-center
                            btn btn-secondary btn-outline-primary 
                            text-light 
                            w-100 mt-4"
                        disabled={isSubmitting || !verify}
                        onClick={() => dispatch(
                            setOTPSent({ otpSent: false }
                            ))}>
                        {verifyLoading ? <ClipLoader size={21} color={"#fff"} />
                            : 'Verify'}
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default OTPVerificationForm