import { useSelector } from 'react-redux';

const UserRole = () => {
    const email = useSelector(state => state.auth.email);

    return (
        <div className='user-role d-flex justify-content-center p-3 border-bottom'>
            <h6 className='text-light'>{email}</h6>
        </div>
    )
}

export default UserRole
