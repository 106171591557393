import * as Yup from 'yup';

const validateNoShellCommands = (value) => {
    const shellCommandPattern = /[`;&|${}<>]/g;
    if (shellCommandPattern.test(value)) {
        return "Invalid Input.";
    }
};

const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required')
        .test('no-shell-commands', 'Invalid characters in email.',
            value => !validateNoShellCommands(value)),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(30, 'Password cannot exceed 30 characters')
        .required('Password is required'),
});

export default loginValidationSchema;