import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/reducers/authReducer';
import { logout } from '../api/auth';
import Cookies from 'js-cookie';
import { axiosPrivate } from '../api/axios';
import Logout from './Logout';

function TitleBar() {
    const mainPage = useSelector((state) => state.route.value.mainpage);
    const titleBarTitle = mainPage.charAt(0).toUpperCase() + mainPage.slice(1);

    return (
        <div className='title-bar bg-light d-flex justify-content-between align-items-center ps-5 pt-3 pe-5 pb-3'>
            <h5 className='text-dark'>{titleBarTitle}</h5>
            <Logout />
        </div>
    )
}

export default TitleBar
