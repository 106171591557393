import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { axiosInstance } from '../../api/axios';
import { scan_detail } from '../../api/scan';
import DateTime from '../shared/DateTime';

const ScanReportHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [scanDetailData, setScanDetailData] = useState({});

    const scan_id = useSelector((state) => state.scan.scan_id)

    useEffect(() => {
        async function handleScanInfoRequest() {
            try {
                const scanDetailResponse = await axiosInstance.post(scan_detail, {
                    'scan_id': scan_id
                });

                if (scanDetailResponse.data.status) {
                    setScanDetailData(scanDetailResponse.data.data)
                }
            } catch (error) {
                navigate('/Scan', {
                    state: { from: location },
                    replace: true,
                });
            }
        }
        handleScanInfoRequest();
    }, []);

    return (
        <>
            <h6>
                <strong>Domain Name:</strong> {scanDetailData['domain_name']}
            </h6>
            <h6>
                <strong>Started At:</strong> <DateTime timestamp={scanDetailData['created_at']} />
            </h6>
        </>
    )
}

export default ScanReportHeader
