import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container, Row, Col, Card, CardHeader, 
  CardBody, CardTitle, ListGroup, ListGroupItem, 
  Badge , Button
} from 'reactstrap';
import { axiosInstance } from '../api/axios';
import { profile } from '../api/auth';
import { setProfile } from '../redux/reducers/profileReducer';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.profile);

  useEffect(() => {
    async function handleProfileInfoRequest() {
      try {
        const response = await axiosInstance.get(profile);
        if (response.status === 200) {
          dispatch(setProfile(response.data));
        } else {
          navigate('/error', { 
            state: { from: location }, 
            replace: true 
          });
        }
      } catch (error) {
        if (error.response.status === 401) {
          navigate('/login', { 
            state: { from: location }, 
            replace: true 
          });
        } else {
          navigate('/error', { 
            state: { from: location }, 
            replace: true 
          });
        }
      }
    }
    handleProfileInfoRequest();
  }, []);

  return (
    <Container>
      <Row>
        <Col md="12">
          <Card>
            <CardBody className="text-center">
              <img
                src={user.avatar? user.avatar: 'https://via.placeholder.com/150'}
                alt="Profile"
                className="img-thumbnail rounded-circle mb-3"
                style={{ width: '150px', height: '150px' }}
              />
              <CardTitle tag="h5">{user.name}</CardTitle>
              <p>{user.email}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md="12">
          <Card>
            <CardHeader>Profile Details</CardHeader>
            <CardBody>
              <ListGroup flush>
                <ListGroupItem>Email: {user.email}</ListGroupItem>
                <ListGroupItem>
                  Account: {user.isVerified? 'Verified': 'Not Verified'}
                  { !user.isVerified? (
                    <Button 
                      color="primary" 
                      size="sm" 
                      className="float-end"
                      onClick={() => {
                        navigate('/verification', { 
                          state: { from: location }, 
                          replace: true 
                        });
                      }}>
                      Verify Account
                    </Button>
                  ): null }
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      
    </Container>
  );
};

export default UserProfile;
