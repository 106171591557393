import { createSlice } from '@reduxjs/toolkit';

const scanDataReducer = createSlice({
    name: 'scanData',
    initialState: {
        value: {
            id: '',
            domainName: '',
            startedAt: '',
            status: '',
        },
        scanProgress: {
            status: 0
        },
        vulnData: [],
        filteredVulnData: [],
        scanToolData: [],
        subdomainInfo: [],
        dnsInfo: [],
        emails: [],
        cveInfo: [],
        portInfo: [],
        vulnSeverityCounts: {
            total: 0,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 0,
        },
        dbSeverityCounts: {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 0,
        },
        totalVulnCount: 0,
        page: 1,
        totalPages: 0, 
    },
    reducers: {
        setData: (state, action) => {
            state.value = action.payload;
        },
        setScanProgress: (state, action) => {
            const { status } = action.payload;
            console.log('ss', status)
            state.status = status
        },
        setVulnData: (state, action) => {
            state.vulnData = action.payload;
        },
        setFilteredVulnData: (state, action) => {
            state.filteredVulnData = action.payload;
        },
        setVulnSeverityCounts: (state, action) => {
            state.vulnSeverityCounts = action.payload;
        },
        setScanToolData: (state, action) => {
            state.scanToolData = action.payload;
        },
        setSubdomainInfo: (state, action) => {
            state.subdomainInfo = action.payload;
        },
        setEmailInfo: (state, action) => {
            state.emails = action.payload;
        },
        setDNSInfo: (state, action) => {
            state.dnsInfo = action.payload;
        },
        setCVEInfo: (state, action) => {
            state.cveInfo = action.payload;
        },
        setPortInfo: (state, action) => {
            state.portInfo = action.payload;
        },
        setDBServerityCounts: (state, action) => {
            state.dbSeverityCounts = action.payload;
        },
        setTotalVulnCount: (state, action) => {
            state.totalVulnCount = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        }
    }
});

export const { 
    setData, setScanProgress, setVulnData, 
    setVulnSeverityCounts, setScanToolData,
    setDNSInfo, setCVEInfo, setPortInfo,
    setSubdomainInfo, setEmailInfo, setDBServerityCounts,
    setTotalVulnCount, setPage, setTotalPages, setFilteredVulnData } = scanDataReducer.actions;
export default scanDataReducer.reducer;