import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scan_tool_data } from '../../api/scan';
import {
    setScanToolData,
    setLoading,
    setDNSInfo,
    setCVEInfo,
    setPortInfo,
    setSubdomainInfo,
    setEmailInfo,
    setVulnSeverityCounts
} from '../../redux/reducers/scanDataReducer';
import ScanReportListView from './ScanReportListView';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../api/axios';
import ScanReportCVEList from './ScanReportCVEList';
import ScanReportPortList from './ScanReportPortList';

const ScanReportInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [subdomainData, setSubdomainData] = useState([]);
    const [scanToolData, setScanToolData] = useState([]);

    const scan_id = useSelector(state => state.scan.scan_id);

    const scanProgres = useSelector(state => state.scanData.scanProgress);
    const subdomainList = useSelector(state => state.scanData.subdomainInfo);
    const emailList = useSelector(state => state.scanData.emails);
    const portInfo = useSelector(state => state.scanData.portInfo);
    const cveInfo = useSelector(state => state.scanData.cveInfo);

    const getSubdomainList = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].tool_name === 'multi') {
                return data[i].tool_output.subdomains;
            }
        }
        return [];
    }

    const getEmailList = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].tool_name === 'theharvester') {
                return data[i].tool_output.Emails;
            }
        }
        return [];
    }

    const getPortList = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].tool_name === 'Nmap') {
                return data[i].tool_output.port_details;
            }
        }
        return [];
    }

    const getCVEList = (data) => {
        let cveList = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].tool_name === 'Nmap') {
                data[i].tool_output.port_details.forEach(portObj => {
                    portObj.script.forEach(scriptObj => {
                        cveList.push(scriptObj);
                    });
                });
                break;
            }
        }
        return cveList;
    }

    const getSeverityCounts = (data) => {
        const severityCounts = {
            total: data.length,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 1
        };

        for (let i = 0; i < data.length; i++) {
            if (data[i].tool_name === 'Nuclei') {
                switch (data[i].tool_output.severity) {
                    case 'critical':
                        severityCounts.critical += 1;
                        break;
                    case 'high':
                        severityCounts.high += 1;
                        break;
                    case 'medium':
                        severityCounts.medium += 1;
                        break;
                    case 'low':
                        severityCounts.low += 1;
                        break;
                    case 'info':
                       
                        severityCounts.info += 1;
                        break;
                    default:
                        break;
                }
            }
            
            return severityCounts;
        }
    }

    useEffect(() => {
        async function handleScanInfoRequest() {
            try {
                const scanToolDataResponse = await axiosInstance.post(scan_tool_data, {
                    'scan_id': scan_id
                });

                if (scanToolDataResponse.data.status) {
                    const subdomainData = scanToolDataResponse.data.data['subdomain_enum'];
                    const dnsData = scanToolDataResponse.data.data['dns_enum'];
                    const vulnData = scanToolDataResponse.data.data['vulnerability_scan'];

                    

                    const cveList = getCVEList(vulnData);
                    dispatch(setCVEInfo(cveList));

                    const portList = getPortList(vulnData);
                    dispatch(setPortInfo(portList));

                    const severityCounts = getSeverityCounts(vulnData);
                    dispatch(setVulnSeverityCounts(severityCounts));

                    const subdomainList = getSubdomainList(subdomainData);
                    dispatch(setSubdomainInfo(subdomainList));

                    dispatch(setDNSInfo(dnsData));

                    const emailList = getEmailList(dnsData);
                    dispatch(setEmailInfo(emailList));

                    dispatch(setScanToolData(vulnData));

                }
            } catch (error) {
                navigate('/Scan', {
                    state: { from: location },
                    replace: true,
                });
            }
        }
        handleScanInfoRequest();
    }, [scanProgres]);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <ScanReportCVEList data={cveInfo} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ScanReportPortList data={portInfo} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ScanReportListView
                        data={subdomainList}
                        title='Subdomains'
                        type='0'
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ScanReportListView
                        data={emailList}
                        title='Emails'
                        type='0'
                    />
                </div>
            </div>
        </>
    )
}

export default ScanReportInfo
