import React from 'react';
import TitleBar from './TitleBar';
import Scan from './dashboard/Scan';
import Profile from './dashboard/Profile';
import { useSelector } from 'react-redux';
import Home from './dashboard/Home';

const Main = () => {
    const mainPage = useSelector((state) => state.route.value.mainpage);

    return (
        <div className='main'>
            <div className="container-fluid g-0">
                <div className="row">
                    <div className="col-md-12 w-100">
                        <TitleBar />
                    </div>

                    <div className="col-md-12 body-scroll body-content">
                        {mainPage === 'scan' ? <Scan />
                            : mainPage === 'profile' ? <Profile />
                                : <Home />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
