import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScanForm from './ScanForm';
import ScanTaskList from '../ScanTaskList';

const Scan = () => {
    const dispatch = useDispatch();

    return (
        <div className='scan'>
            <div className="container-fluid g-0 p-4">
                <div className="row">
                    <div className="col-md-6 p-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Scan
                                </h5>
                                <ScanForm />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 p-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Tasks
                                </h5>
                                <ScanTaskList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Scan;
