import axios from 'axios';
import { APP_REST_URL } from './config';

const BASE_URL = `${APP_REST_URL}`;

export const axiosUnAuth = axios.create({
    baseURL: BASE_URL,
});

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})