import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Image from '../../assets/images/image.png';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container, Row, Col, Card, CardHeader,
    CardBody, CardTitle, ListGroup, ListGroupItem,
    Badge, Button, Table
} from 'reactstrap';
import { axiosInstance } from '../../api/axios';
import { scan_summary } from '../../api/scan';
import { setScanProgress, setFilteredVulnData } from '../../redux/reducers/scanDataReducer';
import VulnDataList from '../VulnDataList';
import { APP_WEBSOCKET_URI } from '../../api/config';
import SeverityChart from './SeverityChart';
import SeverityCount from './SeverityCount';
import { Bar, Doughnut } from 'react-chartjs-2';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    BarElement, 
    Title, 
    Tooltip, 
    Legend, 
    ArcElement 
} from 'chart.js';
import { 
    setVulnData, 
    setVulnSeverityCounts,
    setDBServerityCounts,
    setTotalVulnCount, 
    setPage, 
    setTotalPages 
} from '../../redux/reducers/scanDataReducer';

ChartJS.register(
    CategoryScale, 
    LinearScale, 
    BarElement, 
    Title, 
    Tooltip, 
    Legend, 
    ArcElement
);

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const uid = useSelector(state => state.auth.uid);
    const vulnSeverityCounts = useSelector(state => state.scanData.dbSeverityCounts);
    const totalVulnCount = useSelector(state => state.scanData.totalVulnCount);
    const vulnData = useSelector(state => state.scanData.vulnData);
    const page = useSelector(state => state.scanData.page);

    const countSeverity= (data) => {
        let counts = {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 0
        }
        let totalCount = 0;

        data.forEach((item) => {
            if (item.severity !== "dns" ){
                
                totalCount += 1;
                counts[item.severity] += 1;
            }
        });
        setDBServerityCounts(counts);
        setTotalVulnCount(totalCount);
    }


    const handleVulnInfoRequest = async () => {
        try {
            const response = await axiosInstance.post(scan_summary, {
                page: page,
                size: 25,
            });

            

            if (response.status === 200) {
                dispatch(setVulnData(response.data.data));
                setPage(response.data.page);
                setTotalPages(response.data.pages);
            }
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true
                });
            }
        }
    }

    const filterData = (data) => {
        
        const filteredData = data
            .filter(item => item?.data?.tool_data?.tool_output?.title && item.severity !== "dns")
        

        dispatch(setFilteredVulnData(filteredData));
    }

    useEffect(() => {
        handleVulnInfoRequest();
        filterData(vulnData);
        const websocket = new WebSocket(
            `${APP_WEBSOCKET_URI}?id=${uid}`
        );

        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.data) {
                handleVulnInfoRequest();
                filterData(vulnData);
            }
        };

        return () => {
            websocket.close();
        };
    }, []);


    const processDataForBarChart = (dataArray) => {
        
        if (!Array.isArray(dataArray)) {
            console.error("Data is not an array:", dataArray);
            return { labels: [], datasets: [] };
        }

        const severityCount = dataArray.reduce((acc, item) => {
            if (item.severity === "critical" || 
                item.severity === "high" || 
                item.severity === "medium" || 
                item.severity === "low" || 
                item.severity === "info") {
                acc[item.severity] = (acc[item.severity] || 0) + 1;
            }
            
            return acc;
        }, {});

        return {
            labels: Object.keys(severityCount),
            datasets: [
                {
                    label: true,
                    data: Object.values(severityCount),
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(153, 102, 255, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    };

    const processDataForDonutChart = (dataArray) => {
        
        if (!Array.isArray(dataArray)) {
            console.error("Data is not an array:", dataArray);
            return { labels: [], datasets: [] };
        }

        const severityCount = dataArray.reduce((acc, item) => {
            if (item.severity === "critical" || 
                item.severity === "high" || 
                item.severity === "medium" || 
                item.severity === "low" || 
                item.severity === "info") {
                acc[item.severity] = (acc[item.severity] || 0) + 1;
            }
            
            return acc;
        }, {});

        return {
            labels: Object.keys(severityCount),
            datasets: [
                {
                    label: false,
                    data: Object.values(severityCount),
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    };

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                align: 'center',
                labels: {
                    boxWidth: 20,
                    padding: 20,
                },
            },
            title: {
                display: false,
            }
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                    stepSize: 1
                },
                beginAtZero: true
            }
        },
    };

    const donutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                align: 'left',
                labels: {
                    boxWidth: 20,
                    padding: 20,
                },
            },
            title: {
                display: false,
            }
        },
    };

    const barChartData = processDataForBarChart(vulnData);
    const donutChartData = processDataForDonutChart(vulnData);

    return (
        <Container className=''>
            <Row style={{
                backgroundColor: '#f8f9fa',
                borderBottom: '0.6rem solid #fff',
                padding: '1rem',
                color: '#495057',
            }}>
                <Col md="10">
                    <CardBody className="text-left w-75">
                        <h5>Vulnerabilities Identified</h5>
                    </CardBody>
                </Col>

                <Col md="2">
                    <div className='d-flex flex-row'>
                        <CardBody className="d-flex justify-content-end w-25 me-3">
                            <h5>{totalVulnCount}</h5>
                        </CardBody>
                    </div>
                </Col>
            </Row>

            <Row style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '5rem',
                backgroundColor: '#f8f9fa',
                paddingLeft: '1rem',
            }}>
                <Col md='6' className='d-flex justify-content-center align-items-center db-chart'>
                    <Card className='w-100 h-100 border-0'>
                        <CardHeader>
                            <CardTitle tag='h5'>Severity Counts</CardTitle>
                        </CardHeader>
                        <CardBody style={{ backgroundColor: '#f8f9fa' }}>
                            <Bar
                                data={barChartData}
                                options={barChartOptions}
                            />
                        </CardBody>
                    </Card>

                </Col>

                <Col md='6' className='d-flex justify-content-center align-items-center db-donut-chart'>
                    <Card className='w-100 h-100 border-0'>
                        <CardHeader>
                            <CardTitle tag='h5'>Severity Distribution</CardTitle>
                        </CardHeader>
                        <CardBody style={{ backgroundColor: '#f8f9fa' }}>
                            <Doughnut
                                data={donutChartData}
                                options={donutChartOptions}
                            />
                        </CardBody>
                    </Card>

                </Col>
            </Row>

            <Row style={{
                marginTop: '1rem',
            }}>
                <Col>
                    <VulnDataList />
                </Col>
            </Row>
        </Container>
    )
}

export default Dashboard