import {createSlice} from '@reduxjs/toolkit';

const authReducer = createSlice({
    name: 'auth',
    initialState: {
        loggedIn: false,
        email: '',
        uid: '',
        isVerified: false,
    },
    reducers: {
        setAuth: (state, action) => {
            const { loggedIn, email } = action.payload
            state.loggedIn = loggedIn
            state.email = email
        },
        setIsVerified: (state, action) => {
            const { isVerified } = action.payload
            state.isVerified = isVerified
        },
        setUID: (state, action) => {
            const { uid } = action.payload
            state.uid = uid
        }
    }
});

export const { setAuth, setIsVerified, setUID } = authReducer.actions;
export default authReducer.reducer;