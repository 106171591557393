import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/reducers/authReducer';
import { logout } from '../api/auth';
import { axiosInstance } from '../api/axios';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosInstance.get(logout);

            if (response.status === 200) {
                navigate('/login', {
                    state: { from: location },
                    replace: true
                });
            } else {
                navigate('/error', {
                    state: { from: location },
                    replace: true
                });
            }
        } catch (err) {
            navigate('/error', {
                state: { from: location },
                replace: true
            });
        }
    }

    return (
        <div>
           <button className='btn text-dark' onClick={handleLogout}>Logout</button> 
        </div>
    )
}

export default Logout
