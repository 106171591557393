import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCVEInfo } from '../../redux/reducers/scanDataReducer';
import { Table } from 'reactstrap';

const ScanReportCVEList = ({data}) => {

    if (data.length === 0) {
        return null;
    }

    return (
        <div className='table-container mt-5'>
            <h5 className="mt-5">
                Vulnerabilities
            </h5>
            <Table className='table table-hover table-striped'>
                <thead>
                    <tr>
                        <th>CVE ID</th>
                        <th>Exploitability</th>
                        <th>CVSS</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{data.id}</td>
                            <td>{data.is_exploit}</td>
                            <td>{data.cvss}</td>
                            <td>{data.type}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ScanReportCVEList
